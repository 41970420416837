/* Available classes for payme form are detailed in  https://docs.pay-me.com/es/flex/v1/clases-css */

#paymeForm .flex-capture > .form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#paymeForm .flex-capture h2 {
  font-weight: 600;
  font-size: 20px;
}

#paymeForm .flex-capture form {
  margin-top: 12px;
  width: 100%;
}

#paymeForm .flex-capture .field {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

#paymeForm .flex-capture .field label {
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

#paymeForm .flex-capture .field > input {
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
}

#paymeForm .flex-capture .field > small {
  color: theme('colors.critical.DEFAULT');
}

#paymeForm .flex-capture .submit {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

#paymeForm .flex-capture .submit > button {
  width: 50%;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  background-color: theme('colors.brand-secondary.DEFAULT');
  color: theme('textColor.brand-secondary.on-bg');
  height: 48px;
}

#paymeForm .flex-capture .submit > button:disabled {
  cursor: not-allowed;
  background-color: theme('colors.gray.200');
  color: theme('textColor.neutral.400');
  border: 1px solid theme('colors.gray.300');
}

#paymeForm .flex-capture pre {
  visibility: hidden;
  height: 0;
}
