@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --space-cart-width: 360px;
    --space-mobile-header-height: 56px;
  }

  /*
  [redacted]
  1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
  2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
  3. Backward compatibility with old global styles. This affects items in flex containers.
  */

  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: theme('colors.neutral.200'); /* 2 */

    min-height: 0; /* 3 */
    min-width: 0; /* 3 */
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  /*
  [redacted]
  1. Use a consistent sensible line-height in all browsers.
  2. Prevent adjustments of font size after orientation changes in iOS.
  3. Use a more readable tab size.
  7. Disable tap highlights on iOS
  */

  html,
  :host {
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    tab-size: 4; /* 3 */
    -webkit-tap-highlight-color: transparent; /* 7 */
    font-family: 'Open Sans', sans-serif;
  }

  /*
  1. Remove the margin in all browsers.
  2. Inherit line-height from "html"" so users can set them as a class directly on the "html" element.
  3. Set text color as zinc-800 by default
  */

  body {
    margin: 0; /* 1 */
    line-height: inherit; /* 2 */
    color: theme('colors.zinc.800'); /* 3 */
  }

  /*
  1. Add the correct height in Firefox.
  2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
  3. Ensure horizontal rules are visible by default.
  */

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
    border-top-width: 1px; /* 3 */
  }

  /*
  Add the correct text decoration in Chrome, Edge, and Safari.
  */

  abbr:where([title]) {
    text-decoration: underline dotted;
  }

  /*
  Remove the default font size and weight for headings.
  */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  /*
  Reset links to optimize for opt-in styling instead of opt-out.
  */

  a {
    color: inherit;
    text-decoration: inherit;
  }

  /*
  Add the correct font weight in Edge and Safari.
  */

  b,
  strong {
    font-weight: bolder;
  }

  /*
  [redacted]
  4. Correct the odd "em"" font sizing in all browsers.
  */

  code,
  kbd,
  samp,
  pre {
    font-size: 1em; /* 4 */
  }

  /*
  Add the correct font size in all browsers.
  */

  small {
    font-size: 80%;
  }

  /*
  Prevent "sub" and "sup" elements from affecting the line height in all browsers.
  */

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /*
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Remove gaps between table borders by default.
  */

  table {
    text-indent: 0; /* 1 */
    border-color: inherit; /* 2 */
    border-collapse: collapse; /* 3 */
  }

  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-feature-settings: inherit; /* 1 */
    font-variation-settings: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-weight: inherit; /* 1 */
    line-height: inherit; /* 1 */
    color: inherit; /* 1 */
    margin: 0; /* 2 */
    padding: 0; /* 3 */
  }

  /*
  Remove the inheritance of text transform in Edge and Firefox.
  */

  button,
  select {
    text-transform: none;
  }

  /*
  1. Correct the inability to style clickable types in iOS and Safari.
  2. Remove default button styles.
  */

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button; /* 1 */
    background-color: transparent; /* 2 */
    background-image: none; /* 2 */
  }

  /*
  Use the modern Firefox focus style for all focusable elements.
  */

  :-moz-focusring {
    outline: auto;
  }

  /*
  Remove the additional ":invalid" styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
  */

  :-moz-ui-invalid {
    box-shadow: none;
  }

  /*
  Add the correct vertical alignment in Chrome and Firefox.
  */

  progress {
    vertical-align: baseline;
  }

  /*
  Correct the cursor style of increment and decrement buttons in Safari.
  */

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  /*
  1. Correct the odd appearance in Chrome and Safari.
  2. Correct the outline style in Safari.
  */

  [type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  /*
  Remove the inner padding in Chrome and Safari on macOS.
  */

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /*
  1. Correct the inability to style clickable types in iOS and Safari.
  2. Change font properties to "inherit" in Safari.
  */

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  /*
  Add the correct display in Chrome and Safari.
  */

  summary {
    display: list-item;
  }

  /*
  Removes the default spacing and border for appropriate elements.
  */

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /*
  Reset default styling for dialogs.
  */
  dialog {
    padding: 0;
  }

  /*
  Prevent resizing textareas horizontally by default.
  */

  textarea {
    resize: vertical;
  }

  /*
  1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
  2. Set the default placeholder color to the user's configured gray 400 color.
  */

  input::placeholder,
  textarea::placeholder {
    opacity: 1; /* 1 */
    color: theme('colors.gray.400'); /* 2 */
  }

  /*
  Set the default cursor for buttons.
  */

  button,
  [role='button'] {
    cursor: pointer;
  }

  /*
  Make sure disabled buttons don't get the pointer cursor.
  */
  :disabled {
    cursor: default;
  }

  /* Make elements with the HTML hidden attribute stay hidden by default */
  [hidden] {
    display: none;
  }

  html,
  body {
    height: auto;
    width: 100%;

    background-color: theme('colors.neutral.100');
  }

  :focus {
    outline: 0;
  }
}

@layer base {
  .add-to-cart-blocker {
    cursor: pointer;
  }

  .add-to-cart-blocker * {
    pointer-events: none;
  }

  .inner-image-zoom-desktop {
    max-height: 25.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner-image-zoom-mobile {
    width: 100%;
  }

  .inner-image-zoom-mobile > div > img {
    max-height: 20.5rem;
    object-fit: contain;
  }

  .inner-image-zoom-mobile > div {
    height: 20.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* 
 * We need this rule for setting margin of the quotation printing pages 
 * See https://github.com/MatthewHerbst/react-to-print/issues/54 
 */
@layer base {
  @media print {
    @page {
      margin: theme('spacing.8');
    }
  }
}

.slider-control-bottomcenter button {
  background: white !important;
  border-radius: 50%;
  border: 1px solid black !important;
  width: 9px !important;
  height: 9px !important;
}

.slider-control-bottomcenter svg {
  display: none !important;
}

.slider-control-bottomcenter li {
  margin-right: 5px;
}
